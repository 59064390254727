import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { globalStore } from "@/store";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/courses/:id",
		name: "curso",
		props: true,
		component: () => import("../views/AboutView.vue"),
		beforeEnter: (to, from, next) => {
			const store = globalStore();
			if (!store.getUser()) {
				console.log("No hay usuario", store.currentUser);
				next({ name: "home" });
			} else {
				next();
			}
		},
	},
	{
		path: "/edit-course/:id",
		name: "Editar Curso",
		props: true,
		component: () => import("../views/EditCourse.vue"),
		beforeEnter: (to, from, next) => {
			const store = globalStore();
			const user = store.getUser();
			const userId = user ? user.id : null;
			if ((userId && userId === "1") || userId === 1) {
				next();
			} else {
				next({ name: "home" });
			}
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
