<template>
	<div
		class="bg-white rounded-md w-14 p-2 flex flex-col items-center justify-between"
	>
		<div class="flex flex-col w-full gap-2">
			<button class="w-full aspect-square bg-blue-800 rounded-md">
				<i class="fas fa-home text-white text-sm" />
			</button>
			<button class="w-full aspect-square bg-gray-300 rounded-md">
				<i class="fas fa-video text-white text-sm" />
			</button>
			<button class="w-full aspect-square bg-gray-300 rounded-md">
				<i class="fas fa-scalpel text-white text-sm" />
			</button>
		</div>
		<button class="w-full aspect-square bg-red-800 rounded-md">
			<i class="fas fa-gear text-white text-sm" />
		</button>
	</div>
	<div class="flex-1 rounded-md flex flex-col gap-4 overflow-auto">
		<main class="flex-1 flex flex-row w-full">
			<div class="flex-1 bg-white rounded-xl px-5 py-5 flex flex-col gap-8">
				<div class="relative w-full h-[50vh] rounded-xl overflow-hidden">
					<img
						src="https://www.drkreisky.com/_astro/about.DaPW-Ri5.png"
						class="object-cover object-center w-full h-full"
					/>
					<div class="absolute inset-0 bg-black bg-opacity-50"></div>
					<main
						class="size-full absolute inset-0 flex flex-col items-start justify-end p-6 sm:p-10 gap-1"
					>
						<h1 class="text-white text-4xl sm:text-6xl font-bold">
							Bienvenido(a) colega!
						</h1>
						<p class="text-white font-normal text-xl sm:text-2xl">
							El compromiso con el estudio constante es el corazón de toda
							cirugía transformadora
						</p>
					</main>
				</div>

				<div v-if="sections.courses" class="flex flex-col gap-4">
					<h2 class="text-3xl font-bold">Tus cursos</h2>
					<main class="flex flex-row gap-4 opacity-100 relative p-2">
						<template v-if="showSoonLayer.courses">
							<div
								class="z-50 top-0 left-0 absolute bg-gray-200/95 w-full h-full rounded-xl p-4 flex flex-col items-center justify-center"
							>
								<i class="fa-regular fa-timer text-6xl text-gray-500"></i>
								<p class="text-gray-500 text-lg">
									<span class="font-normal">Muy pronto!</span>
								</p>
							</div>
						</template>
						<div
							v-for="(course, index) in data.courses"
							:key="index"
							class="relative border border-gray-300 rounded-2xl p-4 min-w-80 max-w-80 flex flex-col gap-4 shadow-md group"
						>
							<div
								class="absolute bg-gray-300 size-full top-0 left-0 rounded-2xl z-50 opacity-80 flex flex-col items-center justify-center"
								v-if="course.level !== gStore.currentUser.level"
							>
								<i class="fa-regular fa-lock text-6xl text-gray-800" />
							</div>
							<div
								class="w-full h-40 rounded-xl overflow-hidden p-2 flex flex-col items-start"
								:style="getBackgroundStyle('https://i.imgur.com/AWpWydH.png')"
							>
								<span
									:class="[
										'top-0 left-0 flex flex-row items-center gap-1 px-2 py-0.5 rounded-md',
										{
											'bg-amber-300 text-amber-700': course.level === 'GOLD',
											'bg-gray-300 text-gray-700': course.level === 'SILVER',
											'bg-blue-300 text-blue-700': course.level === 'DIAMOND',
										},
									]"
								>
									<i class="fa-regular fa-medal z-20"></i>
									<span class="z-10 text-nowrap">{{ course.level }}</span>
								</span>
							</div>
							<div class="flex flex-col gap-0 items-start">
								<h1 class="text-2xl font-bold leading-6">{{ course.title }}</h1>
								<p class="mt-1 text-gray-500 text-sm">
									<i class="fa-regular fa-circle-user mr-1"></i>
									<span class="font-normal">{{ course.author }}</span>
								</p>
							</div>
							<template v-if="course.level === gStore.currentUser.level">
								<a
									:href="course.link"
									class="text-center font-bold w-full border border-gray-300 rounded-xl py-2 transition-all hover:bg-black hover:text-white"
								>
									<i class="fa-regular fa-right-to-bracket"></i>
									Ingresar
								</a>
							</template>
						</div>
					</main>
				</div>

				<div v-if="sections.events" class="flex flex-col gap-4 relative">
					<h2 class="text-3xl font-bold">Eventos, Directos y más</h2>
					<main class="flex flex-row gap-4 opacity-100 relative p-2">
						<template v-if="showSoonLayer.events">
							<div
								class="z-50 top-0 left-0 absolute bg-gray-200/95 w-full h-full rounded-xl p-4 flex flex-col items-center justify-center"
							>
								<i class="fa-regular fa-timer text-6xl text-gray-500"></i>
								<p class="text-gray-500 text-lg">
									<span class="font-normal">Muy pronto!</span>
								</p>
							</div>
						</template>
						<div
							v-for="(update, index) in data.updates"
							:key="index"
							class="w-full border border-gray-300 rounded-2xl p-4 min-w-80 max-w-80 flex flex-col gap-4 shadow-md group"
						>
							<div class="flex flex-col gap-2 items-start">
								<p class="mt-1 text-gray-500 text-sm">
									<i class="fa-regular fa-circle-user mr-1"></i>
									<span class="font-normal">{{ update.author }}</span>
								</p>
								<h1 class="text-xl font-semibold leading-5">
									{{ update.title }}
								</h1>
								<p class="mt-1 text-gray-500 text-sm">
									{{ update.description }}
								</p>
								<a
									href="#"
									class="mt-1 text-blue-800 no-underline group-hover:underline text-sm"
								>
									<i class="fa-regular fa-arrow-up-right-from-square mr-1"></i>
									Seguir leyendo
								</a>
							</div>
						</div>
					</main>
				</div>

				<div v-if="sections.updates" class="flex flex-col gap-4 relative">
					<h2 class="text-3xl font-bold">Últimas Actualizaciones</h2>
					<main class="flex flex-row gap-4 opacity-100 relative p-2">
						<template v-if="showSoonLayer.updates">
							<div
								class="z-50 top-0 left-0 absolute bg-gray-200/95 w-full h-full rounded-xl p-4 flex flex-col items-center justify-center"
							>
								<i class="fa-regular fa-timer text-6xl text-gray-500"></i>
								<p class="text-gray-500 text-lg">
									<span class="font-normal">Muy pronto!</span>
								</p>
							</div>
						</template>
						<div
							v-for="(update, index) in data.updates"
							:key="index"
							class="w-full border border-gray-300 rounded-2xl p-4 min-w-80 max-w-80 flex flex-col gap-4 shadow-md group"
						>
							<div class="flex flex-col gap-2 items-start">
								<p class="mt-1 text-gray-500 text-sm">
									<i class="fa-regular fa-circle-user mr-1"></i>
									<span class="font-normal">{{ update.author }}</span>
								</p>
								<h1 class="text-xl font-semibold leading-5">
									{{ update.title }}
								</h1>
								<p class="mt-1 text-gray-500 text-sm">
									{{ update.description }}
								</p>
								<a
									href="#"
									class="mt-1 text-blue-800 no-underline group-hover:underline text-sm"
								>
									<i class="fa-regular fa-arrow-up-right-from-square mr-1"></i>
									Seguir leyendo
								</a>
							</div>
						</div>
					</main>
				</div>

				<div v-if="sections.cases" class="flex flex-col gap-4 relative">
					<h2 class="text-3xl font-bold">Casos Comentados</h2>
					<main class="flex flex-row gap-4 opacity-100 relative p-2">
						<template v-if="showSoonLayer.cases">
							<div
								class="z-50 top-0 left-0 absolute bg-gray-200/95 w-full h-full rounded-xl p-4 flex flex-col items-center justify-center"
							>
								<i class="fa-regular fa-timer text-6xl text-gray-500"></i>
								<p class="text-gray-500 text-lg">
									<span class="font-normal">Muy pronto!</span>
								</p>
							</div>
						</template>
						<div
							v-for="(caseItem, index) in data.cases"
							:key="index"
							class="w-full border border-gray-300 rounded-2xl p-4 min-w-80 max-w-80 flex flex-col gap-4 shadow-md group"
						>
							<div
								:style="getBackgroundStyle(caseItem.image)"
								class="w-full h-40 rounded-xl overflow-hidden"
							></div>
							<p class="mt-1 text-gray-500 text-sm">
								<i class="fa-regular fa-circle-user mr-1"></i>
								<span class="font-normal">{{ caseItem.author }}</span>
							</p>
							<h1 class="text-xl font-semibold leading-5">
								{{ caseItem.title }}
							</h1>
							<p class="mt-1 text-gray-500 text-sm">
								{{ caseItem.description }}
							</p>
							<a
								href="#"
								class="mt-1 text-blue-800 no-underline group-hover:underline text-sm flex flex-row items-center"
							>
								<i class="fa-regular fa-eye mr-1"></i>
								<span>Ver caso</span>
								<i class="fa-regular fa-chevron-right ml-1"></i>
							</a>
						</div>
					</main>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { exec } from "@/classes/database.js";
import { globalStore } from "@/store";
async function fn(fn) {
	try {
		const res = await fn();
		return [null, res];
	} catch (err) {
		console.error(err);
		return [err, null];
	}
}

export default {
	name: "HomeView",
	data() {
		return {
			userLevel: "",
			gStore: globalStore(),
			sections: {
				courses: true,
				updates: true,
				cases: true,
				events: true,
			},
			showSoonLayer: {
				courses: false,
				updates: true,
				cases: true,
				events: true,
			},
			data: {
				courses: [],
				updates: [],
				cases: [],
			},
		};
	},
	methods: {
		getBackgroundStyle(imageUrl) {
			return {
				backgroundImage: `url(${imageUrl})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			};
		},
		async getUpdates() {
			const command =
				"db.collection('kreiskyacademy-updates').find({}).toArray()";
			const vars = {};
			const [err, res] = await fn(() => exec(command, vars));
			if (err || !res) {
				await this.getUpdates();
				return;
			}
			console.log("🚀 ~ getUpdates ~ res:", res.result);
			this.data.updates = res.result;
			return res.result;
		},
		async getCourses() {
			const command =
				"db.collection('kreiskyacademy-courses').find({}).toArray()";
			const vars = {};
			const [err, res] = await fn(() => exec(command, vars));
			if (err || !res) {
				await this.getCourses();
				return;
			}
			console.log("🚀 ~ getCourses ~ res:", res.result);
			this.data.courses = res.result;
			return res.result;
		},
		async getCases() {
			const command =
				"db.collection('kreiskyacademy-cases').find({}).toArray()";
			const vars = {};
			const [err, res] = await fn(() => exec(command, vars));
			if (err || !res) {
				await this.getCases();
				return;
			}

			console.log("🚀 ~ getCases ~ res:", res.result);
			this.data.cases = res.result;
			return res.result;
		},
	},
	async mounted() {
		const store = globalStore();
		await this.getCourses();
		await this.getUpdates();
		await this.getCases();
		const user = store.currentUser;
		this.userLevel = user.level;
	},
};
</script>
